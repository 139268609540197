<template>
  <v-container class="d-flex justify-center mt-8">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <qrcode-vue value="https://genera.alumbraenergia.es/formulario" render-as="svg"></qrcode-vue>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-around mt-4">
        <v-btn x-large to="/listado" color="primary">
          <v-icon left>mdi-account-group</v-icon>Ver listado
        </v-btn>
        <v-btn x-large to="/formulario" color="primary">
          <v-icon left>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'Home',
  components: {
    QrcodeVue
  },
  mounted() {
    document.querySelector('svg').setAttribute('width', '100%');
    document.querySelector('svg').setAttribute('height', '100%');
    document.querySelector('svg').setAttribute('style', '');
  }
}
</script>

<style lang="scss">
svg {
  max-width: 550px;
  width: 100%;
}

div > svg {
  max-height: 500px;
  height: 100%;
}
</style>
